<template>
	<div class="item">
		<div class="header-block" :class="{ large: type === 'large' }">
			<span class="title">{{ title }}</span>
			<div class="filter-row">
				<el-select
					v-if="chartData[activeCode].seriesQuery"
					v-model="seriesIdsMap[activeCode]"
					multiple
					collapse-tags
					:multiple-limit="selectLimitComputed || 0"
					:clearable="chartData[activeCode].seriesQuery?.all"
					placeholder="车系筛选"
					@change="onChangeSeries"
					class="filter-select"
				>
					<!-- popper-class="el-popper-cockpit" -->
					<el-option
						v-for="item in seriesListComputed"
						:key="item.code"
						:label="item.name"
						:value="item.code"
						:disabled="
							selectMinComputed === 1 &&
							seriesIdsMap[activeCode].length === 1 &&
							seriesIdsMap[activeCode].includes(item.code)
						"
					/>
				</el-select>
				<el-cascader
					v-if="chartData[activeCode].driverQuery"
					v-model="driverIdsMap"
					:options="chartData[activeCode].driverQuery.list"
					:props="{ multiple: true }"
					placeholder="选择司机画像"
					:show-all-levels="false"
					collapse-tags
					clearable
					@change="onChangeDriver"
					style="margin-top: 5px"
					class="filter-select"
				/>
				<!-- popper-class="el-popper-cockpit" -->
			</div>
		</div>
		<div class="body-block">
			<div class="tab-list" :class="{ large: type === 'large' }">
				<div
					v-for="(tab, index) in tabList"
					:key="index"
					class="tab-item"
					:class="{ active: activeCode === tab.code }"
					@click="handleTab(tab.code)"
				>
					{{ tab.name }}
				</div>
			</div>
			<div class="tab-content" :class="{ large: type === 'large' }">
				<Chart
					v-for="(tab, index) in tabList"
					:key="index"
					:config="chartData[tab.code]"
					:style="{ visibility: tab.code === activeCode ? 'visible' : 'hidden' }"
					class="chart-item"
				/>
			</div>
		</div>
	</div>
</template>

<script setup>
import Chart from './chart.vue'
import { watch, ref, computed, onMounted, onBeforeUnmount } from 'vue'

let _timer = null

// eslint-disable-next-line no-undef
const props = defineProps({
	type: String,
	title: String,
	tabList: {
		type: Object,
		default: () => [],
	},
	chartData: {
		type: Object,
		default: () => [],
	},
	seriesIdsData: {
		type: Object,
		default: () => [],
	},
})
// eslint-disable-next-line no-undef, no-unused-vars
const emit = defineEmits(['changeSeries', 'changeDriver'])

const seriesIdsMap = ref(props.seriesIdsData)
const driverIdsMap = ref({})
const activeCode = ref(props.tabList[0].code)

const seriesListComputed = computed(() => props.chartData[activeCode.value].seriesQuery?.list)
const selectLimitComputed = computed(() =>
	props.chartData[activeCode.value].seriesQuery?.all ? 0 : 3,
)
const selectMinComputed = computed(() =>
	props.chartData[activeCode.value].seriesQuery?.all ? 0 : 1,
)
watch(
	() => props.chartData,
	val => {
		//
	},
)
const handleTab = code => {
	activeCode.value = code
}
const onChangeSeries = val => {
	emit('changeSeries', val, activeCode.value)
}
const onChangeDriver = val => {
	const province = val.reduce((acc, cur) => {
		if (cur[0] === 'area') {
			!acc.includes(cur[1]) && acc.push(cur[1])
			return acc
		}
	}, [])
	const params = {
		province,
		city: val.filter(item => item[0] === 'area').map(item => item[2]),
		age: val.filter(item => item[0] === 'age').map(item => item[1]),
		sex: val.filter(item => item[0] === 'sex').map(item => item[1]),
	}
	emit('changeDriver', params, activeCode.value)
}
onMounted(() => {
	// _timer = setInterval(() => {
	// 	let curIndex = props.tabList.findIndex(tab => tab.code === activeCode.value)
	// 	let maxIndex = props.tabList.length - 1
	// 	if (curIndex === maxIndex) {
	// 		curIndex = 0
	// 	} else {
	// 		curIndex++
	// 	}
	// 	activeCode.value = props.tabList[curIndex].code
	// }, 10 * 1000)
})
onBeforeUnmount(() => {
	// clearInterval(_timer)
})
</script>
<style lang="less" scoped>
.filter-select {
	// 	// width: 250px !important;
	// 	background: #05012c;
	// 	border: 1px solid rgba(99, 208, 254, 0.24);
	// 	box-shadow: inset 0 0 10px 4px rgba(92, 218, 255, 0.05);
	// 	:deep(.el-select-dropdown__wrap) {
	// 		background: #040c3c;
	// 	}
	// 	:deep(.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after) {
	// 		display: none !important;
	// 	}
	// 	:deep(.el-input__wrapper) {
	// 		box-shadow: none;
	// 		border-radius: 0;
	// 		// border: 1px solid #020e3b;
	// 		background-color: #030830;
	// 	}
	// 	:deep(.el-input.is-focus .el-input__wrapper) {
	// 		box-shadow: unset;
	// 	}
	// 	:deep(.el-input__wrapper .is-focus) {
	// 		box-shadow: unset;
	// 	}
	// 	:deep(.el-tag) {
	// 		background-color: rgba(17, 129, 225, 0.3);
	// 		border-radius: 2px;
	// 		color: #e7f7ff;
	// 		font-size: 12px;
	// 	}
	:deep(.el-popper) {
		border-radius: 0;
		border: 1px solid #63d0fe !important;
		background-color: #020e3b;
		color: #e7f7ff;
	}
	:deep(.el-popper__arrow::before) {
		background: #020e3b;
		border-color: #63d0fe;
	}
	.el-popper {
		background: #020e3b !important;
	}
	// 	.el-cascader-menu {
	// 		border-color: #63d0fe;
	// 	}
	// 	.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
	// 		background-color: #061132 !important;
	// 	}
	// 	.el-checkbox__inner {
	// 		background-color: #020e3b !important;
	// 		&::after {
	// 			border-color: #061132 !important;
	// 		}
	// 	}
	// 	.el-select-dropdown__item {
	// 		background-color: #020e3b;
	// 		color: #e7f7ff;
	// 		padding: 0 10px;
	// 	}
	// 	.el-select-dropdown__item.hover {
	// 		background-color: #1c2855;
	// 		color: #63d0fe;
	// 	}
	// 	.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
	// 		background-color: #020e3b;
	// 		color: #63d0fe;
	// 		font-weight: 400;
	// 	}
	// 	&.is-light .el-popper__arrow::before {
	// 		background: #020e3b;
	// 		border-color: #63d0fe;
	// 	}
	// 	.el-cascader-node {
	// 		color: #e7f7ff;
	// 	}
	// 	.el-checkbox__input.is-checked .el-checkbox__inner {
	// 		background-color: #63d0fe;
	// 		border-color: #63d0fe;
	// 	}
	// 	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
	// 		background-color: #63d0fe;
	// 		border-color: #63d0fe;
	// 	}
	// 	.el-cascader-node__postfix {
	// 		color: #63d0fe;
	// 	}
	// 	.el-cascader-node:not(.is-disabled):focus,
	// 	.el-cascader-node:not(.is-disabled):hover {
	// 		background-color: #1c2855;
	// 	}
	// 	:deep(.el-input .el-select__caret) {
	// 		color: #63d0fe;
	// 	}
	// 	// :deep(.el-input) {
	// 	// 	height: 32px;
	// 	// 	line-height: 32px;
	// 	// }

	// 	:deep(.el-input.is-focus .el-input__wrapper) {
	// 		border-color: #63d0fe;
	// 	}
	// 	:deep(.el-select__tags) {
	// 		width: 100% !important;
	// 		max-width: 192px !important;
	// 	}
	// 	:deep(.el-select-tags-wrapper.has-prefix) {
	// 		margin-left: 4px !important;
	// 	}
}

.el-select-dropdown {
	background-color: #020e3b;
}
// .el-select-dropdown__item {
// 	background-color: #020e3b;
// 	color: #e7f7ff;
// 	padding: 0 10px;
// }
// .el-select-dropdown__item.hover {
// 	background-color: #1c2855;
// 	color: #63d0fe;
// }
// .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
// 	background-color: #020e3b;
// 	color: #63d0fe;
// 	font-weight: 400;
// }
:deep(.el-popper) {
	border-radius: 0;
	border: 1px solid #63d0fe !important;
	background-color: #020e3b;
	color: #e7f7ff;
}
:deep(.el-popper__arrow::before) {
	background: #020e3b !important;
	border-color: #63d0fe;
}

.el-popper-cockpit {
	background: #040c3c !important;
}
:deep(.el-select-dropdown__item) {
	background-color: #020e3b;
	color: #e7f7ff;
	padding: 0 10px;
}
:deep(.el-select-dropdown__item) {
	background-color: #1c2855;
	color: #63d0fe;
}
// :deep(.el-select-dropdown__item.hover) {
// 	background-color: #1c2855;
// 	color: #63d0fe;
// }
.el-select {
	width: 190px;
}
:deep(.el-select-dropdown__wrap) {
	background-color: #040c3c;
}
:deep(.el-cascader) {
	width: 134px;
	margin-left: 6px;
}
:deep(.el-input__inner) {
	font-size: 12px;
}
:deep(.el-input__inner::placeholder) {
	color: #d7f2ff;
}
:deep(.part .el-cascader__tags .el-tag:first-child) {
	max-width: 50px;
}
:deep(.part .el-select-tags-wrapper .el-tag:first-child) {
	max-width: 50px;
}
:deep(.el-select-tags-wrapper.has-prefix) {
	margin: 0;
}
:deep(.el-input .el-select__caret) {
	color: #63d0fe;
}
:deep(.el-input) {
	height: 32px;
	line-height: 32px;
}
:deep(.el-cascader:not(.is-disabled):hover .el-input__wrapper) {
	box-shadow: none;
}
:deep(.el-input__wrapper) {
	box-shadow: none;
	border-radius: 0;
	border: 1px solid #63d0fe3d;
	background-color: transparent;
}
:deep(.el-input.is-focus .el-input__wrapper) {
	border-color: #63d0fe;
}
:deep(.el-tag) {
	background-color: rgba(17, 129, 225, 0.3);
	border-radius: 2px;
	color: #e7f7ff;
	font-size: 12px;
	padding: 0 5px;
}
:deep(.el-tag .el-tag__close) {
	color: #fff;
}
:deep(.el-input.is-focus .el-input__wrapper) {
	box-shadow: none !important;
}
:deep(.el-select:hover .el-input__wrapper) {
	box-shadow: none;
}
// :deep(.el-select-dropdown__item.hover, .el-select-dropdown__item:hover) {
// 	background-color: #030830;
// }
// .el-select-dropdown__item.hover {
// 	background-color: #1c2855;
// 	color: #63d0fe;
// }
:deep(.el-select-dropdown.is-multiple .el-select-dropdown__item.selected) {
	background-color: #020e3b !important;
	color: #63d0fe;
	font-weight: 400;
}
:deep(.el-popper.is-light) {
	border-radius: 0;
	border: 1px solid #63d0fe !important;
	background-color: #020e3b;
	color: #e7f7ff;
}
:deep(.el-popper__arrow::before) {
	background: #020e3b;
	border-color: #63d0fe;
}
</style>
<style lang="less" scoped>
@import '../../../../styles/mixins.less';
html {
	font-size: 16px;
}

.item {
	width: 100%;
	height: 300px;
	margin-bottom: 20px;
	background: #000129;
	.flexible(column, center, flex-start);
	.header-block {
		position: relative;
		width: 100%;
		height: 44px;
		background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/title_small.png')
			no-repeat;
		background-size: 100% 100%;
		.flexible(row, center, space-between);
		&.large {
			// width: 798px;
			width: 100%;
			height: 44px;
			background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/title_large_1.png')
				no-repeat;
			background-size: 100% 100%;
		}
		.title {
			line-height: 44px;
			padding-left: 30px;
			font-weight: 600;
			font-size: 20px;
			color: #95f2ff;
		}
		.filter-row {
			position: absolute;
			// right: 60px;
			right: 3.75rem;
			top: 6px;
			z-index: 100;
			.flexible(column, flex-end, flex-start);
		}
	}
	.body-block {
		width: 100%;
		height: 256px;
		.flexible(row, center, flex-start);
		.tab-list {
			width: 28%;
			height: 100%;
			&.large {
				width: 167px;
			}
			.flexible(column, center, center);
			.tab-item {
				width: 120px;
				height: 33px;
				margin-bottom: 20px;
				background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/tab.png')
					no-repeat;
				background-size: 100% 100%;
				font-weight: 400;
				font-size: 12px;
				color: #b4c4c8;
				cursor: pointer;
				.flexible(row, center, center);
				&:last-child {
					margin-bottom: 0;
				}
				&.active {
					background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/tab_active.png')
						no-repeat;
					background-size: 100% 100%;
					font-weight: 500;
					color: #95f2ff;
				}
			}
		}
		.tab-content {
			position: relative;
			// width: 378px;
			flex: 1;
			height: 245px;
			&.large {
				// width: 588px;
			}
			.chart-item {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}
// @media screen and (min-width: 1650px) {
// 	.part .el-select {
// 		width: 160px;
// 	}
// 	:deep(.el-cascader) {
// 		width: 160px;
// 	}
// 	:deep(.part .el-cascader__tags .el-tag:first-child) {
// 		max-width: initial;
// 	}
// 	:deep(.part .el-select-tags-wrapper .el-tag:first-child) {
// 		max-width: initial;
// 	}
// }
// @media screen and (min-width: 1800px) {
// 	.part .el-select {
// 		width: 160px;
// 	}
// 	:deep(.el-cascader) {
// 		width: 156px;
// 	}
// }
</style>
<style lang="less" scoped>
@media screen and (min-width: 1150px) and (max-width: 1550px) {
	.header-block {
		height: 1.875rem !important;
		.title {
			padding-left: 20px !important;
			font-size: 13px !important;
		}
		.company-select {
			width: 150px !important;
		}
		.filter-row {
			right: 2.75rem !important;
			top: 0 !important;
			height: 26px !important;
		}
	}
	.el-select {
		width: 9.375rem !important;
	}
}
</style>

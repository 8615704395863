<template>
	<div class="chart">
		<div v-if="!config.isPie" class="c-w">
			<ECharts :option="option" />
		</div>
		<div v-else class="c-w fx">
			<div class="pie-w">
				<img
					v-if="option.series[0].radius.length > 1"
					src="https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20230802-1987/bg_circle.png"
				/>
				<ECharts :option="option" />
			</div>
			<div class="pie-legend">
				<div v-for="item in pieList" :key="item.name" class="p-i">
					<i :style="{ backgroundColor: item.color }"></i>
					<div class="c-1">{{ item.name }}</div>
					<div class="c-2">{{ item.rate }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { watch, reactive, ref } from 'vue'
import * as allConfig from '../config'
import { pieColors } from '../config'
import ECharts from '@/views/chart/components/ECharts'
// eslint-disable-next-line no-undef
const props = defineProps({
	config: {
		type: Object,
		default: () => ({
			title: '',
			code: '',
			seriesQuery: null,
			driverQuery: null,
			isPie: false,
			suffix: '元',
			x: [],
			y: [],
			list: [],
		}),
	},
})
// eslint-disable-next-line no-undef, no-unused-vars
// const emits = defineEmits(['changeSeries', 'changeDriver'])
const option = reactive({
	...Object.assign({}, allConfig[props.config.code]),
})
// console.log('get option ==>', props.config.code, allConfig, option)
const pieList = ref([])

// 补齐剩余的 series，或者扣除多余的
const fillDiffSeries = fillCount => {
	if (fillCount > 0) {
		for (let i = 0; i < fillCount; i++) {
			const seriesItem = Object.assign({}, option.series[0])
			option.series = [...option.series, seriesItem]
		}
	} else {
		const length = option.series.length
		// 至少保留一项，让后续有数据的时候可以正常填充
		if (-fillCount === length) {
			option.series = option.series.slice(0, length === 1 ? 1 : length - 1)
			option.series[0].data = []
			option.series[0].name = ''
		} else {
			option.series = option.series.slice(0, option.series.length + fillCount)
		}
	}
}
watch(
	() => props.config.x,
	val => {
		if (!props.config.isPie) {
			if (Array.isArray(option.xAxis)) {
				option.xAxis[0].data = [...val]
			} else {
				option.xAxis.data = [...val]
			}
		}
	},
)
watch(
	() => props.config.y,
	val => {
		if (!props.config.isPie) {
			if (val.length !== option.series.length) {
				fillDiffSeries(val.length - option.series.length)
			}
			option.series.forEach((item, index) => {
				item.name = val[index]
			})
		}
	},
)
watch(
	() => props.config.list,
	val => {
		if (!props.config.isPie) {
			if (val.length !== option.series.length) {
				fillDiffSeries(val.length - option.series.length)
			}

			val.forEach((arr, index) => {
				option.series[index].data = [...arr]
			})
		} else {
			option.series[0].data = [...val]
			pieList.value = val.map((item, index) => ({
				...item,
				color: pieColors[index],
			}))
		}
	},
)
</script>

<style lang="less" scoped>
.chart {
	width: 100%;
	height: 100%;
	// padding-right: 10px;
	box-sizing: border-box;
}
.c-h {
	position: relative;
	height: 50px;
}
.c-bg {
	.flexible(row, flex-end, space-between);
	.c-l {
		width: 75px;
		height: 50px;
		background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20230802-1987/bg_tit2_l.png')
			no-repeat center center;
	}
	.c-m {
		flex: 1;
		height: 50px;
		background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20230802-1987/bg_tit2_r.png')
			no-repeat left center;
		background-size: 100% 50px;
	}
	.c-dot {
		width: 41px;
		height: 20px;
		margin-bottom: -4px;
		background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20230802-1987/bg_tit_dot.png')
			no-repeat center center;
	}
}
.c-con {
	.flexible(row, center, space-between);
	position: absolute;
	left: 0;
	top: 4px;
	right: 0;
	&.part {
		.tit {
			font-size: 16px;
			padding-left: 40px;
		}
	}
	.tit {
		flex: 1;
		font-size: 18px;
		font-weight: 600;
		color: #e7f7ff;
		padding-left: 50px;
	}
	.select {
		.flexible(row, center, space-between);
		height: 32px;
		line-height: 32px;
		padding: 0 9px;
		margin-left: 10px;
		cursor: pointer;
		background: rgba(17, 129, 225, 0.1);
		box-shadow: inset 0 0 10px 4px rgba(92, 218, 255, 0.06);
		span {
			flex: 1;
			font-size: 12px;
			line-height: 12px;
			color: #e7f7ff;
		}
		.rect {
			width: 12px;
			height: 12px;
			margin-right: 10px;
		}
		.down {
			width: 12px;
			height: 12px;
			margin-left: 10px;
		}
	}
}
.c-w {
	width: 100%;
	height: 245px;
	overflow: hidden;
	background-color: #010530;
}
.fx {
	.flexible(row, center, space-between);
}
.pie-w {
	position: relative;
	// width: 245px;
	// height: 245px;
	width: 220px;
	height: 220px;
	flex: 0 0 220px;
	img {
		position: absolute;
		width: 144px;
		height: 144px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
.pie-legend {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	justify-content: center;
	flex: 1;
	// max-width: 50%;
	// margin-left: 10px;
	// margin-right: 10px;
	.p-i {
		.flexible(row, center, flex-end);
		font-size: 12px;
		color: #e7f7ff;
		line-height: 12px;
		margin: 10px 0;
		.c-1 {
			flex: 1;
			margin-left: 10px;
			// max-width: calc((100vw - 124px) / 3 - 362px);
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.c-2 {
			margin-right: 8px;
			min-width: 43px;
			text-align: right;
			flex: 0 0 43px;
		}
		i {
			display: block;
			width: 8px;
			height: 8px;
			flex: 0 0 8px;
		}
	}
}
.el-select {
	width: 190px;
}
.part .el-select {
	width: 130px;
}
:deep(.el-cascader) {
	width: 134px;
	margin-left: 6px;
}
:deep(.el-input__inner) {
	font-size: 12px;
}
:deep(.el-input__inner::placeholder) {
	color: #d7f2ff;
}
:deep(.part .el-cascader__tags .el-tag:first-child) {
	max-width: 50px;
}
:deep(.part .el-select-tags-wrapper .el-tag:first-child) {
	max-width: 50px;
}
:deep(.el-select-tags-wrapper.has-prefix) {
	margin: 0;
}
:deep(.el-input .el-select__caret) {
	color: #63d0fe;
}
:deep(.el-input) {
	height: 32px;
	line-height: 32px;
}
:deep(.el-cascader:not(.is-disabled):hover .el-input__wrapper) {
	box-shadow: none;
}
:deep(.el-input__wrapper) {
	box-shadow: none;
	border-radius: 0;
	border: 1px solid #020e3b;
	background-color: #020e3b;
}
:deep(.el-input.is-focus .el-input__wrapper) {
	border-color: #63d0fe;
}
:deep(.el-tag) {
	background-color: rgba(17, 129, 225, 0.3);
	border-radius: 2px;
	color: #e7f7ff;
	font-size: 12px;
	padding: 0 5px;
}
:deep(.el-tag .el-tag__close) {
	color: #fff;
}
:deep(.el-input.is-focus .el-input__wrapper) {
	box-shadow: none !important;
}
:deep(.el-select:hover .el-input__wrapper) {
	box-shadow: none;
}
@media screen and (min-width: 1650px) {
	.part .el-select {
		width: 160px;
	}
	:deep(.el-cascader) {
		width: 160px;
	}
	:deep(.part .el-cascader__tags .el-tag:first-child) {
		max-width: initial;
	}
	:deep(.part .el-select-tags-wrapper .el-tag:first-child) {
		max-width: initial;
	}
}
@media screen and (min-width: 1800px) {
	.part .el-select {
		width: 160px;
	}
	:deep(.el-cascader) {
		width: 156px;
	}
}
</style>

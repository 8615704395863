<template>
	<div class="scroll-x">
		<div v-loading="loading" class="cockpit" :class="{ bg_small: isSmallScreen }" ref="cockpitRef">
			<header class="header-block">
				<!-- <el-row type="flex" justify="start" class="header-left"> -->
				<img
					class="logo"
					:class="{ small: isSmallScreen }"
					src="https://wechatdn.yiautos.com/renrenjia/v1211/logo.png"
				/>
				<!-- src="https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20230802-1987/logo.png" -->
				<el-select
					v-model="companyIds"
					multiple
					collapse-tags
					:teleported="false"
					@change="onChangeCompany"
					class="company-select"
					:class="{ small: isSmallScreen }"
				>
					<el-checkbox-group v-model="companyIds" text-color="#040C3C" fill="#63D0FE">
						<el-option
							v-for="item in companyList"
							:key="item.code"
							:label="item.name"
							:value="item.code"
						>
							<el-checkbox :label="item.code" style="pointer-events: none">
								{{ item.name }}
							</el-checkbox>
						</el-option>
					</el-checkbox-group>

					<div class="option-bottom">
						<el-checkbox v-model="checkAll" @change="checkAllCompany">全选</el-checkbox>
						<el-checkbox v-model="checkReverse" @change="checkReverseCompany">反选</el-checkbox>
					</div>
				</el-select>
				<!-- </el-row> -->
				<!-- <el-row type="flex" justify="end" class="header-right"> -->
				<div class="time-block" :class="{ small: isSmallScreen }">
					<img
						src="https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/decoration.png"
						class="decoration-icon"
					/>
					{{ currTime }}
					<img
						src="https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/decoration.png"
						class="decoration-icon"
					/>
				</div>
				<div
					class="exit-block"
					:class="{ small: isSmallScreen }"
					@click="handleScreenfull"
					href="/chart-cockpit"
					target="_blank"
				>
					<img
						src="https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/decoration.png"
						class="decoration-icon"
					/>
					全屏
					<svg-icon
						:icon-class="isFullscreen ? 'fullscreen-exit' : 'fullscreen'"
						class="screen-icon"
					></svg-icon>
					<!-- <img
							src="https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/decoration.png"
							class="decoration-icon"
						/> -->
				</div>
				<!-- </el-row> -->
			</header>
			<div v-if="!isSmallScreen" class="body-block">
				<el-row type="flex" direction="column" justify="start" class="left-block">
					<Item
						ref="chartItemRef"
						title="经营信息总览"
						:tab-list="[
							{ code: 'srqs', name: '收入趋势' },
							{ code: 'zcqs', name: '支出趋势' },
							{ code: 'gssrgc', name: '公司收入构成' },
							{ code: 'ptsrgc', name: '平台收入构成' },
						]"
						:chart-data="chartDataMap"
						:seriesIdsData="seriesIdsMap"
						@changeSeries="onChangeSeries"
						@changeDriver="onChangeDriver"
					></Item>
					<Item
						ref="chartItemRef1"
						title="费用分析"
						:tab-list="[
							{ code: 'tczbf', name: '退车整备费' },
							{ code: 'wyjsr', name: '违约金收入' },
							{ code: 'cxxzyj', name: '车系新增押金' },
							{ code: 'cxljyj', name: '车系累计押金' },
						]"
						:chart-data="chartDataMap"
						:seriesIdsData="seriesIdsMap"
						@changeSeries="onChangeSeries"
						@changeDriver="onChangeDriver"
					></Item>
					<Item
						ref="chartItemRef"
						title="司机分析"
						:tab-list="[
							{ code: 'yqysjdyfb', name: '已签约司机地域分布' },
							{ code: 'yqysjnlfb', name: '已签约司机年龄分布' },
							{ code: 'yqysjxbfb', name: '已签约司机性别分布' },
						]"
						:chart-data="chartDataMap"
						:seriesIdsData="seriesIdsMap"
						@changeSeries="onChangeSeries"
						@changeDriver="onChangeDriver"
					></Item>
				</el-row>
				<el-row type="flex" direction="column" class="center-block">
					<div class="overview">
						<div class="data-row vehicle-assets">
							{{ overviewData.vehicleAssets || 0 }}
							<span class="unit">辆</span>
						</div>
						<div class="data-row vehicle-stock">
							{{ overviewData.stockVehicleCount || 0 }}
							<span class="unit">辆</span>
						</div>
						<div class="data-row rental-rate">
							{{ overviewData.rtoRate || 0 }}
							<span class="unit">%</span>
						</div>
						<div class="data-row expire-30">
							{{ overviewData.realtimeDriverExpiring || 0 }}
							<span class="unit">人</span>
						</div>
						<div class="data-row rent-arrears">
							{{ overviewData.arrearsAmount || 0 }}
							<span class="unit">万元</span>
						</div>
					</div>
					<div style="position: absolute; bottom: -374px; width: 100%; padding: 0 20px">
						<Item
							ref="chartItemRef"
							type="large"
							title="车辆安全分析"
							:tab-list="[
								{ code: 'wzfx', name: '违章分析' },
								{ code: 'sgfx', name: '事故分析' },
							]"
							:chart-data="chartDataMap"
							:seriesIdsData="seriesIdsMap"
							@changeSeries="onChangeSeries"
							@changeDriver="onChangeDriver"
						></Item>
					</div>
				</el-row>
				<el-row type="flex" direction="column" justify="start" class="right-block">
					<Item
						ref="chartItemRef"
						title="车系出租总览"
						:tab-list="[
							{ code: 'cxczl', name: '车系出租率' },
							{ code: 'cxzqfb', name: '车系租期分布' },
							{ code: 'cxtjyz', name: '车系台均月租' },
							{ code: 'cxljzj', name: '车系累计租金' },
						]"
						:chart-data="chartDataMap"
						:seriesIdsData="seriesIdsMap"
						@changeSeries="onChangeSeries"
						@changeDriver="onChangeDriver"
					></Item>
					<Item
						ref="chartItemRef"
						title="维保金额总览"
						:tab-list="[
							{ code: 'wbtjje', name: '维保台均金额' },
							{ code: 'wbljje', name: '维保累计金额' },
						]"
						:chart-data="chartDataMap"
						@changeSeries="onChangeSeries"
						@changeDriver="onChangeDriver"
					></Item>
					<Item
						ref="chartItemRef"
						title="合同信息概览"
						:tab-list="[
							{ code: 'htslfx', name: '合同数量分析' },
							{ code: 'wldqhts', name: '未来到期合同数' },
						]"
						:chart-data="chartDataMap"
						:seriesIdsData="seriesIdsMap"
						@changeSeries="onChangeSeries"
						@changeDriver="onChangeDriver"
					></Item>
				</el-row>
			</div>
			<div v-else class="body-block_width">
				<el-row type="flex" direction="column" class="center-block">
					<div class="overview">
						<div class="data-row vehicle-assets">
							{{ overviewData.vehicleAssets || 0 }}
							<span class="unit">辆</span>
						</div>
						<div class="data-row vehicle-stock">
							{{ overviewData.stockVehicleCount || 0 }}
							<span class="unit">辆</span>
						</div>
						<div class="data-row rental-rate">
							{{ overviewData.rtoRate || 0 }}
							<span class="unit">%</span>
						</div>
						<div class="data-row expire-30">
							{{ overviewData.realtimeDriverExpiring || 0 }}
							<span class="unit">人</span>
						</div>
						<div class="data-row rent-arrears">
							{{ overviewData.arrearsAmount || 0 }}
							<span class="unit">万元</span>
						</div>
					</div>
				</el-row>
				<el-row type="flex" justify="space-between" style="width: 100%">
					<el-row type="flex" direction="column" justify="start" class="left-block">
						<Item
							ref="chartItemRef"
							title="经营信息总览"
							:tab-list="[
								{ code: 'srqs', name: '收入趋势' },
								{ code: 'zcqs', name: '支出趋势' },
								{ code: 'gssrgc', name: '公司收入构成' },
								{ code: 'ptsrgc', name: '平台收入构成' },
							]"
							:chart-data="chartDataMap"
							:seriesIdsData="seriesIdsMap"
							@changeSeries="onChangeSeries"
							@changeDriver="onChangeDriver"
						></Item>
						<Item
							ref="chartItemRef1"
							title="费用分析"
							:tab-list="[
								{ code: 'tczbf', name: '退车整备费' },
								{ code: 'wyjsr', name: '违约金收入' },
								{ code: 'cxxzyj', name: '车系新增押金' },
								{ code: 'cxljyj', name: '车系累计押金' },
							]"
							:chart-data="chartDataMap"
							:seriesIdsData="seriesIdsMap"
							@changeSeries="onChangeSeries"
							@changeDriver="onChangeDriver"
						></Item>
						<Item
							ref="chartItemRef"
							title="司机分析"
							:tab-list="[
								{ code: 'yqysjdyfb', name: '已签约司机地域分布' },
								{ code: 'yqysjnlfb', name: '已签约司机年龄分布' },
								{ code: 'yqysjxbfb', name: '已签约司机性别分布' },
							]"
							:chart-data="chartDataMap"
							:seriesIdsData="seriesIdsMap"
							@changeSeries="onChangeSeries"
							@changeDriver="onChangeDriver"
						></Item>
					</el-row>
					<el-row type="flex" direction="column" justify="start" class="right-block">
						<Item
							ref="chartItemRef"
							title="车系出租总览"
							:tab-list="[
								{ code: 'cxczl', name: '车系出租率' },
								{ code: 'cxzqfb', name: '车系租期分布' },
								{ code: 'cxtjyz', name: '车系台均月租' },
								{ code: 'cxljzj', name: '车系累计租金' },
							]"
							:chart-data="chartDataMap"
							:seriesIdsData="seriesIdsMap"
							@changeSeries="onChangeSeries"
							@changeDriver="onChangeDriver"
						></Item>
						<Item
							ref="chartItemRef"
							title="维保金额总览"
							:tab-list="[
								{ code: 'wbtjje', name: '维保台均金额' },
								{ code: 'wbljje', name: '维保累计金额' },
							]"
							:chart-data="chartDataMap"
							:seriesIdsData="seriesIdsMap"
							@changeSeries="onChangeSeries"
							@changeDriver="onChangeDriver"
						></Item>
						<Item
							ref="chartItemRef"
							title="合同信息概览"
							:tab-list="[
								{ code: 'htslfx', name: '合同数量分析' },
								{ code: 'wldqhts', name: '未来到期合同数' },
							]"
							:chart-data="chartDataMap"
							:seriesIdsData="seriesIdsMap"
							@changeSeries="onChangeSeries"
							@changeDriver="onChangeDriver"
						></Item>
					</el-row>
				</el-row>
				<div style="width: 100%">
					<Item
						ref="chartItemRef"
						type="large"
						title="车辆安全分析"
						:tab-list="[
							{ code: 'wzfx', name: '违章分析' },
							{ code: 'sgfx', name: '事故分析' },
						]"
						:chart-data="chartDataMap"
						:seriesIdsData="seriesIdsMap"
						@changeSeries="onChangeSeries"
						@changeDriver="onChangeDriver"
					></Item>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'ChartCockpit',
})
</script>
<script setup name="ChartCockpit">
import { reactive, ref, inject, onMounted, onBeforeUnmount, computed } from 'vue'
import Item from './components/item'
import dayjs from 'dayjs'
import { chartsMap, driverOptions } from './config'
import Request from '@/http'
import screenfull from 'screenfull'
import { debounce } from '@/utils/util'

let _timer = null
let _driverParams = {}
const sf = screenfull

// const basePath = 'https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20230802-1987'
// const names = ['车辆资产', '车辆库存', '车辆出租率', '未来30天到期', '欠租金额']
// const suffixs = ['辆', '辆', '%', '人', '万元']
// const blockList = reactive(
// 	names.map((name, index) => ({
// 		name,
// 		value: '-',
// 		suffix: suffixs[index],
// 		img: `${basePath}/icon_0${index + 1}.png`,
// 	})),
// )

const currTime = ref('')

const companyIds = ref([])
const companyList = ref([])
const loading = ref(false)
const checkAll = ref(false)
const checkReverse = ref(false)
const overviewData = ref({})
const $message = inject('$message')
const isFullscreen = ref(false)
const chartItemRef = ref(null)
const chartItemRef1 = ref(null)

const cockpitRef = ref(null)
const screenWidth = ref(null)
const isSmallScreen = ref(true)

// const getModel = computed(() => (multiple.value ? companyIds.value : companyIds.value[0]))

// const onSwitch = () => {
// 	multiple.value = !multiple.value
// 	companyIds.value = companyIds.value.length ? [companyIds.value[0]] : []
// 	getRealData()
// }

const getList = () => {
	let obj = {}
	Object.keys(chartsMap).forEach(key => {
		obj[key] = {
			code: key,
			title: chartsMap[key],
			x: [],
			list: [],
			seriesQuery: null,
			driverQuery: null,
		}
	})
	return obj
}

const chartDataMap = reactive(getList())
// const seriesIdsMap = reactive(Array(Object.keys(chartsMap).length).fill([]))
const seriesIdsMap = reactive({})

const fetchAreaList = async () => {
	try {
		const { code, msg, data } = await Request.post('/suf4-bi-service/cockpit-data/getAdTreeList', {
			companyIds: companyIds.value,
		})
		if (code !== 200) throw new Error(msg)

		chartDataMap['cxzqfb'].driverQuery = {
			list: [
				{
					label: '区域',
					value: 'area',
					children: [...(data || [])],
				},
				...driverOptions,
			],
		}
	} catch (err) {
		$message.error(err.message)
	}
}
const setSeriesListToChart = seriesList => {
	const top3Series = seriesList.slice(0, 3).map(item => item.code)
	// 车系新增押金、车系累计押金、车系台均月租、车系累计租金、车系出租率
	;['cxxzyj', 'cxljyj', 'cxtjyz', 'cxljzj', 'cxczl'].forEach(key => {
		chartDataMap[key].seriesQuery = {
			list: [...seriesList],
		}
		seriesIdsMap[key] = [...top3Series]
	})
	// 车系租期分布、维保台均金额、维保累计金额、事故分析、已签约司机地域分布、已签约司机年龄分布、已签约司机性别分布、违约金收入、违章分析
	;[
		'cxzqfb',
		'wbtjje',
		'wbljje',
		'sgfx',
		'yqysjdyfb',
		'yqysjnlfb',
		'yqysjxbfb',
		'wyjsr',
		'wzfx',
		'tczbf',
	].forEach(key => {
		chartDataMap[key].seriesQuery = {
			all: true, // 不限制3个车系，不默认填充3条数据，不限制最少选择1个
			list: [...seriesList],
		}
		seriesIdsMap[key] = null
	})
}
const fetchSeriesList = async () => {
	try {
		const { code, msg, data } = await Request.post(
			'/suf4-bi-service/cockpit-data/carSeries',
			companyIds.value,
		)
		if (code !== 200) throw new Error(msg)
		setSeriesListToChart((data || []).map(item => ({ name: item.seriesName, code: item.seriesId })))
	} catch (err) {
		$message.error(err.message)
	}
}
const fetchCompanyList = async () => {
	try {
		const { code, msg, data } = await Request.get(
			'/suf4-user-service/company/select?isPermission=true',
		)
		if (code !== 200) throw new Error(msg)
		companyIds.value = [data.map(item => item.companyId)[0]]
		companyList.value = data.map(item => ({ name: item.companyName, code: item.companyId }))
		await fetchSeriesList()
		fetchAreaList()
		getRealData()

		// 定时器，每1分钟刷新一次
		_timer = setInterval(() => {
			getRealData()
		}, 1000 * 60)
	} catch (err) {
		$message.error(err.message)
	}
}
const requestFn = async (params, requestUrl, callback, method = 'post') => {
	try {
		const { code, msg, data } = await Request[method](requestUrl, {
			companyIds: companyIds.value,
			...params,
		})
		if (code !== 200) throw new Error(msg)
		callback(data)
	} catch (err) {
		$message.error(err.message)
	} finally {
		loading.value = false
	}
}
const onChangeSeries = (val, key) => {
	seriesIdsMap[key] = [...val]
	actuator[`getData_${key}`](key)
}
const onChangeDriver = (val, key) => {
	_driverParams = Object.assign({}, val)
	actuator[`getData_${key}`](key)
}
const handleScreenfull = () => {
	if (!sf.isEnabled) {
		$message.warning('你的浏览器不支持')
		return false
	}
	sf.toggle()
}

const actuator = {
	// 收入趋势
	getData_srqs: key => {
		requestFn({}, '/suf4-bi-service/cockpit-data/revenue/businessOverview', data => {
			chartDataMap[key].x = data.x || []
			chartDataMap[key].list = [
				data.receiptsAmount || [],
				data.breachPenaltyAmount || [],
				data.damageAmountAmount || [],
				data.letRate || [],
			]
		})
	},
	// 支出趋势
	getData_zcqs: key => {
		requestFn({}, '/suf4-bi-service/cockpit-data/businessOverview', data => {
			chartDataMap[key].x = data.x || []
			chartDataMap[key].list = [data.maintenanceAmount || [], data.hostLingAmount || []]
		})
	},
	// 合同数量分析
	getData_htslfx: key => {
		requestFn({}, '/suf4-bi-service/cockpit-data/numOfNewContracts', data => {
			chartDataMap[key].x = data.x || []
			chartDataMap[key].list = [data.contractNum || [], data.endContractNum || []]
		})
	},
	// 未来到期合同数
	getData_wldqhts: key => {
		requestFn({}, '/suf4-bi-service/cockpit-data/numOfFutureContractExpirations', data => {
			chartDataMap[key].x = data.x || []
			chartDataMap[key].list = [data.contractNum || []]
		})
	},
	// 车系出租率
	getData_cxczl: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/carSeriesLettingRate',
			data => {
				chartDataMap[key].x = data.x || []
				chartDataMap[key].y = Object.keys(data?.map || {})
				chartDataMap[key].list = Object.values(data?.map || {})
			},
		)
	},
	// 车系租期分布
	getData_cxzqfb: key => {
		requestFn(
			{
				carSeriesIds: seriesIdsMap[key],
				provinceCode: _driverParams?.province || null,
				cityCode: _driverParams?.city || null,
				ageRange: _driverParams?.age || null,
				sex: _driverParams?.sex || null,
			},
			'/suf4-bi-service/cockpit-data/carSeriesLetDist',
			data => {
				chartDataMap[key].isPie = true
				chartDataMap[key].list = [...(data || [])]
			},
		)
	},
	// 公司收入构成
	getData_gssrgc: key => {
		requestFn({}, '/suf4-bi-service/cockpit-data/companyIncomeConstitute', data => {
			chartDataMap[key].isPie = true
			chartDataMap[key].list = [...(data || [])]
		})
	},
	// 车系台均月租
	getData_cxtjyz: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/avgMonthlyRentalOfCarSeries',
			data => {
				chartDataMap[key].x = data.x || []
				chartDataMap[key].y = Object.keys(data?.map || {})
				chartDataMap[key].list = Object.values(data?.map || {})
			},
		)
	},
	// 车系累计租金
	getData_cxljzj: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/rentalOfCarSeries',
			data => {
				chartDataMap[key].x = data.x || []
				chartDataMap[key].y = Object.keys(data?.map || {})
				chartDataMap[key].list = Object.values(data?.map || {})
			},
		)
	},
	// 平台收入构成
	getData_ptsrgc: key => {
		requestFn({}, '/suf4-bi-service/cockpit-data/platformRevenueComposition', data => {
			chartDataMap[key].isPie = true
			chartDataMap[key].list = [...(data || [])]
		})
	},
	// 车系新增押金
	getData_cxxzyj: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/newDepositOfCarSeries',
			data => {
				chartDataMap[key].x = data.x || []
				chartDataMap[key].y = Object.keys(data?.map || {})
				chartDataMap[key].list = Object.values(data?.map || {})
			},
		)
	},
	// 车系累计押金
	getData_cxljyj: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/accruedDepositOfCarSeries',
			data => {
				chartDataMap[key].x = data.x || []
				chartDataMap[key].y = Object.keys(data?.map || {})
				chartDataMap[key].list = Object.values(data?.map || {})
			},
		)
	},
	// 退车整备费
	getData_tczbf: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/returnCarReserveFee',
			data => {
				chartDataMap[key].x = data.x || []
				chartDataMap[key].list = [data?.maintenanceIncome || [], data?.netIncome || []]
			},
		)
	},
	// 违约金收入
	getData_wyjsr: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/breachPenalty',
			data => {
				chartDataMap[key].x = data.x || []
				chartDataMap[key].list = [data?.breachPenalty || []]
			},
		)
	},
	// 维保台均金额
	getData_wbtjje: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/maintenanceAvgAmount',
			data => {
				chartDataMap[key].x = data.x || []
				chartDataMap[key].list = [
					data?.serviceAmounts || [],
					data?.keepAmounts || [],
					data?.wearAmounts || [],
				]
			},
		)
	},
	// 维保累计金额
	getData_wbljje: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/maintenanceCountAmount',
			data => {
				chartDataMap[key].x = data.x || []
				chartDataMap[key].list = [
					data?.serviceAmounts || [],
					data?.keepAmounts || [],
					data?.wearAmounts || [],
				]
			},
		)
	},
	// 事故分析
	getData_sgfx: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/accidentAnalysis',
			data => {
				chartDataMap[key].x = data.x || []
				chartDataMap[key].list = [data?.accidentAmounts || [], data?.numberOfAccidents || []]
			},
		)
	},
	// 违章分析
	getData_wzfx: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/violateAnalysis',
			data => {
				chartDataMap[key].x = data.x || []
				chartDataMap[key].list = [data?.violateQty || [], data?.violateAmount || []]
			},
		)
	},
	// 已签约司机地域分布
	getData_yqysjdyfb: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/geographicalDistribution',
			data => {
				chartDataMap[key].isPie = true
				chartDataMap[key].list = [...(data || [])]
			},
		)
	},
	// 已签约司机年龄分布
	getData_yqysjnlfb: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/ageDistribution',
			data => {
				chartDataMap[key].isPie = true
				chartDataMap[key].list = [...(data || [])]
			},
		)
	},
	// 已签约司机性别分布
	getData_yqysjxbfb: key => {
		requestFn(
			{ carSeriesIds: seriesIdsMap[key] },
			'/suf4-bi-service/cockpit-data/genderDistribution',
			data => {
				chartDataMap[key].isPie = true
				chartDataMap[key].list = [...(data || [])]
			},
		)
	},
}
const getRealData = () => {
	loading.value = true
	currTime.value = dayjs().format('YYYY-MM-DD HH:mm:ss')

	requestFn(
		{},
		`/suf4-asset-service/cockpit-data/overview?companyIds=${companyIds.value}`,
		data => {
			overviewData.value = data || {}
		},
		'get',
	)
	Object.keys(chartsMap).forEach(key => {
		const funKey = `getData_${key}`
		actuator[funKey](key)
	})
}
const onChangeCompany = async val => {
	if (val.length === 0) {
		$message.warning('请至少选择一个公司')
		return
	}
	if (val.length === companyList.value.length) {
		checkAll.value = true
	} else {
		checkAll.value = false
	}
	await fetchSeriesList()
	fetchAreaList()
	getRealData()
}
const checkAllCompany = async () => {
	// 全选情况下, 再次点击, 默认勾选第1个公司
	if (checkAll.value === false) {
		companyIds.value = [companyList.value[0].code]
	} else {
		companyIds.value = companyList.value.map(item => item.code)
	}
	checkReverse.value = false
	await fetchSeriesList()
	fetchAreaList()
	getRealData()
}
const checkReverseCompany = async () => {
	// 全选情况下, 点击反选, 默认勾选第1个公司
	if (checkAll.value === true) {
		companyIds.value = [companyList.value[0].code]
	} else {
		let curSelects = companyIds.value
		companyIds.value = companyList.value
			.filter(item => !curSelects.includes(item.code))
			.map(item => item.code)
	}
	checkAll.value = false
	await fetchSeriesList()
	fetchAreaList()
	getRealData()
}
const change = () => {
	if (sf.isEnabled) {
		isFullscreen.value = sf.isFullscreen
	}
}
const resizeClientWidth = () => {
	if (!cockpitRef.value) return
	let width = cockpitRef.value.clientWidth
	screenWidth.value = width
	isSmallScreen.value = width > 1140 ? false : true
}
onMounted(() => {
	fetchCompanyList()
	if (sf.isEnabled) {
		sf.on('change', change)
	}

	setTimeout(() => {
		resizeClientWidth()
	}, 100)
})
onBeforeUnmount(() => {
	clearInterval(_timer)
	if (sf.isEnabled) {
		sf.off('change', change)
	}
})
window.onresize = function (e) {
	resizeClientWidth()
}
</script>
<style lang="less" scoped>
.company-select {
	// width: 250px !important;
	background: #05012c;
	border: 1px solid rgba(99, 208, 254, 0.24);
	box-shadow: inset 0 0 10px 4px rgba(92, 218, 255, 0.05);
	:deep(.el-select-dropdown__wrap) {
		background: #040c3c;
	}
	:deep(.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after) {
		display: none !important;
	}
	:deep(.el-input__wrapper) {
		box-shadow: none;
		border-radius: 0;
		// border: 1px solid #020e3b;
		background-color: #030830;
	}
	:deep(.el-input.is-focus .el-input__wrapper) {
		box-shadow: unset;
	}
	:deep(.el-input__wrapper .is-focus) {
		box-shadow: unset;
	}
	:deep(.el-tag) {
		background-color: rgba(17, 129, 225, 0.3);
		border-radius: 2px;
		color: #e7f7ff;
		font-size: 12px;
	}
	:deep(.el-popper) {
		border-radius: 0;
		border: 1px solid #63d0fe !important;
		background-color: #020e3b;
		color: #e7f7ff;
	}
	:deep(.el-popper__arrow::before) {
		background: #020e3b;
		border-color: #63d0fe;
	}
	.el-cascader-menu {
		border-color: #63d0fe;
	}
	.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
		background-color: #061132 !important;
	}
	.el-checkbox__inner {
		background-color: #020e3b !important;
		&::after {
			border-color: #061132 !important;
		}
	}
	.el-select-dropdown__item {
		background-color: #020e3b;
		color: #e7f7ff;
		padding: 0 10px;
	}
	.el-select-dropdown__item.hover {
		background-color: #1c2855;
		color: #63d0fe;
	}
	.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
		background-color: #020e3b;
		color: #63d0fe;
		font-weight: 400;
	}
	&.is-light .el-popper__arrow::before {
		background: #020e3b;
		border-color: #63d0fe;
	}
	.el-cascader-node {
		color: #e7f7ff;
	}
	.el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #63d0fe;
		border-color: #63d0fe;
	}
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #63d0fe;
		border-color: #63d0fe;
	}
	.el-cascader-node__postfix {
		color: #63d0fe;
	}
	.el-cascader-node:not(.is-disabled):focus,
	.el-cascader-node:not(.is-disabled):hover {
		background-color: #1c2855;
	}
	:deep(.el-input .el-select__caret) {
		color: #63d0fe;
	}
	// :deep(.el-input) {
	// 	height: 32px;
	// 	line-height: 32px;
	// }

	:deep(.el-input.is-focus .el-input__wrapper) {
		border-color: #63d0fe;
	}
	:deep(.el-select__tags) {
		width: 100% !important;
		max-width: 192px !important;
	}
	:deep(.el-select-tags-wrapper.has-prefix) {
		margin-left: 4px !important;
	}
}
</style>
<style lang="less" scoped>
@import '../../../styles/mixins.less';
::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
}
.cockpit {
	min-height: 100vh;
	padding-bottom: 50px;
	// min-width: 1920px;
	background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/bg.png')
		no-repeat;
	background-size: 100% 100%;
	background-color: #000129;
	&.bg_small {
		background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/bg_small.png')
			no-repeat;
		background-size: 100% 100%;
	}
	.header-block {
		position: relative;
		width: 100%;
		height: remFont(130);
		background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/header_1.png')
			no-repeat;
		background-size: 100% 100%;
		.logo {
			position: absolute;
			left: 10px;
			top: 26px;
			width: 90px;
			// height: 24px;
			&.small {
				left: 20px;
				top: 10px;
			}
		}
		.company-select {
			position: absolute;
			left: 120px;
			top: 26px;
			width: 196px;
			&.small {
				left: 20px;
				top: 52px;
			}
			.option-bottom {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				height: 32px;
				margin: 0 10px;
				border-top: 1px solid #263d5d;
			}
		}
		.time-block,
		.exit-block {
			position: absolute;
			height: 32px;
			padding: 0 8px;
			font-size: 14px;
			color: #e7f7ff;
			border: 1px solid rgba(99, 208, 254, 0.24);
			box-shadow: inset 0 0 10rpx 4rpx rgba(92, 218, 255, 0.05);
			.flexible(row, center, space-between);
			.decoration-icon {
				width: 12rpx;
				height: 12rpx;
			}
			.screen-icon {
				color: #37e7f7;
			}
		}
		.time-block {
			width: 190px;
			right: 110px;
			top: 25px;
			&.small {
				right: 7px;
				top: 52px;
			}
		}
		.exit-block {
			right: 10px;
			top: 25px;
			width: 69px;
			margin-left: 20px;
			&.small {
				right: 7px;
				top: 10px;
			}
		}
	}
	.body-block {
		padding: 0 10px;
		margin-top: -30px;
		.flexible(row, flex-start, space-between);
		.left-block,
		.right-block {
			width: 29%;
			// width: 510px;
			// flex: 1 1 auto;
		}
		.center-block {
			position: relative;
			// width: 820px;
			width: 43%;
			height: 586px;
			// flex: 0 0 820px;
			// background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/overview_bg_1.png')
			// 	no-repeat;
			background-size: 100% 100%;
			.flexible(column, center, flex-start);
			.overview {
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				// width: 653px;
				height: 566px;
				background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/overview.png')
					no-repeat;
				background-size: 100% 100%;
				.data-row {
					position: absolute;
					width: 120px;
					height: 36px;
					color: #ffac54;
					font-size: 36px;
					line-height: 36px;
					font-weight: 700;
					font-family: 'DIN-Alternate';
					text-align: center;
					.flexible(row, center, flex-start);
					&.vehicle-assets {
						top: 30px;
						left: 42%;
					}
					&.vehicle-stock {
						top: 140px;
						left: 10%;
					}
					&.rental-rate {
						top: 140px;
						right: 4%;
					}
					&.expire-30 {
						top: 330px;
						left: 10%;
					}
					&.rent-arrears {
						width: 130px;
						top: 330px;
						right: -5%;
					}
					.unit {
						font-family: PingFangSC-SNaNrpxibold;
						font-weight: 600;
						font-size: 14px;
						padding-left: 6px;
					}
				}
			}
		}
	}
}
.scroll-x {
	overflow-x: auto;
}
.option-bottom {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 30px;
}
.switch-button {
	cursor: pointer;
	color: #409eff;
	margin-right: 17px;
	font-size: 14px;
	font-weight: 600;
}
:deep(.el-select:hover .el-input__wrapper) {
	box-shadow: none;
}
:deep(.el-input__wrapper .el-icon) {
	color: #63d0fe;
}
</style>
<style lang="less" scoped>
// 小屏幕样式
.body-block_width {
	width: 100%;
	padding: 0 30px;
	box-sizing: border-box;
	.flexible(column, center, space-between);
	.left-block,
	.right-block {
		width: 49%;
		// flex: 1 1 auto;
	}
	.center-block {
		position: relative;
		width: 820px;
		height: 586px;
		// flex: 0 0 820px;
		background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/overview_bg.png')
			no-repeat;
		background-size: 100% 100%;
		.flexible(column, center, flex-start);
		.overview {
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 653px;
			height: 566px;
			background: url('https://oss-prod-vsc-master.renrenjia.com.cn/renrenjia-admin-prod/20231023-1718/overview.png')
				no-repeat;
			background-size: 100% 100%;
			.data-row {
				position: absolute;
				width: 120px;
				height: 36px;
				color: #ffac54;
				font-size: 36px;
				line-height: 36px;
				font-weight: 700;
				font-family: 'DIN-Alternate';
				text-align: center;
				.flexible(row, center, flex-start);
				&.vehicle-assets {
					top: 30px;
					left: 293px;
				}
				&.vehicle-stock {
					top: 140px;
					left: 103px;
				}
				&.rental-rate {
					top: 140px;
					right: 30px;
				}
				&.expire-30 {
					top: 330px;
					left: 46px;
				}
				&.rent-arrears {
					top: 330px;
					right: 4px;
				}
				.unit {
					font-family: PingFangSC-SNaNrpxibold;
					font-weight: 600;
					font-size: 14px;
					padding-left: 6px;
				}
			}
		}
	}
}
</style>
<style lang="less" scoped>
html {
	font-size: 16px;
}
@media screen and (min-width: 1150px) {
	.header-block {
		height: 4.8rem !important;
	}
	.body-block {
		margin-top: 0 !important;
	}
}
</style>

import * as echarts from 'echarts'

export const chartsMap = {
	srqs: '收入趋势',
	zcqs: '支出趋势',
	gssrgc: '公司收入构成',
	ptsrgc: '平台收入构成',

	tczbf: '退车整备费',
	wyjsr: '违约金收入',
	cxxzyj: '车系新增押金',
	cxljyj: '车系累计押金',

	yqysjdyfb: '已签约司机地域分布',
	yqysjnlfb: '已签约司机年龄分布',
	yqysjxbfb: '已签约司机性别分布',

	wzfx: '违章分析',
	sgfx: '事故分析',

	cxczl: '车系出租率',
	cxzqfb: '车系租期分布',
	cxtjyz: '车系台均月租',
	cxljzj: '车系累计租金',

	wbtjje: '维保台均金额',
	wbljje: '维保累计金额',

	htslfx: '合同数量分析',
	wldqhts: '未来到期合同数',
}
export const pieColors = [
	'#00A3FE',
	'#FFAC54',
	'#5CDAFF',
	'#51EB96',
	// '#B1B1B1',
	'#8388FB',
	'#64DDB8',
	'#B07CE5',
	'#FFEA7A',
	'#FB7C8B',
]
export const driverOptions = [
	{
		label: '年龄',
		value: 'age',
		children: [
			{ label: '24以下', value: '24以下' },
			{ label: '25-30', value: '25-30' },
			{ label: '31-35', value: '31-35' },
			{ label: '36-40', value: '36-40' },
			{ label: '41以上', value: '41以上' },
		],
	},
	{
		label: '性别',
		value: 'sex',
		children: [
			{ label: '男', value: '男' },
			{ label: '女', value: '女' },
		],
	},
]
export const getLineSeriesItem = unit => ({
	name: '',
	type: 'line',
	connectNulls: true,
	showSymbol: false,
	tooltip: formatterTooltip(unit),
	data: [],
})
const getLinearColor = (firstColor, endColor) => {
	return {
		color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
			{ offset: 0, color: firstColor },
			{ offset: 1, color: endColor },
		]),
	}
}
const formatterTooltip = unit => ({
	valueFormatter: function (value) {
		return `${value ? value : 0}${unit}`
	},
})
const getLineOption = unit => ({
	color: ['#FFAC54', '#5CDAFF', '#51EB96'],
	legend: {
		...legendStyle,
	},
	grid: {
		...gridStyle,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'axis',
	},
	xAxis: [
		{
			...xAxisStyle,
			type: 'category',
			data: [],
		},
	],
	yAxis: {
		...yAxisStyle,
		name: unit,
	},
	series: [getLineSeriesItem(unit)],
})
const gridStyle = {
	bottom: 40,
	right: 15,
}
const legendStyle = {
	top: 10,
	right: 'auto',
	itemWidth: 14,
	itemHeight: 8,
	itemGap: 20,
	textStyle: {
		color: '#A1AEC1',
	},
}
const tooltipStyle = {
	borderColor: '#326E9C',
	backgroundColor: '#020E3B',
	textStyle: {
		color: '#E7F7FF',
		fontSize: 12,
	},
}
const xAxisStyle = {
	axisLabel: {
		color: '#A1AEC1',
	},
	axisTick: {
		show: false,
	},
	axisLine: {
		show: true,
		lineStyle: {
			color: '#5E7697',
		},
	},
}
const yAxisStyle = {
	nameTextStyle: {
		color: '#A1AEC1',
		padding: [0, 40, 0, 0],
	},
	axisTick: {
		show: false,
	},
	axisLabel: {
		color: '#A1AEC1',
	},
	splitLine: {
		show: true,
		lineStyle: {
			color: 'rgba(188, 232, 255, 0.2)',
			type: [4, 4],
		},
	},
}
const stackSeriesOfBarStyle = {
	type: 'bar',
	barWidth: 18,
	stack: 'Ad',
}
const pieStyle = {
	itemStyle: {
		borderColor: '#010530',
		borderWidth: 3,
	},
	label: {
		show: false,
		position: 'center',
	},
	labelLine: {
		show: false,
	},
	emptyCircleStyle: {
		color: '#002C63',
	},
}
export const srqs = {
	color: ['#fff'],
	legend: {
		...legendStyle,
	},
	grid: {
		...gridStyle,
		right: 30,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'axis',
		axisPointer: {
			type: 'cross',
			label: {
				color: '#000129',
				backgroundColor: '#E1F5FF',
			},
			crossStyle: {
				color: '#E1F5FF',
			},
		},
	},
	xAxis: [
		{
			...xAxisStyle,
			type: 'category',
			data: [],
		},
	],
	yAxis: [
		{
			...yAxisStyle,
			name: '万元',
			alignTicks: true,
		},
		{
			...yAxisStyle,
			name: '%',
			nameTextStyle: {
				color: '#A1AEC1',
				padding: [0, 0, 0, 30],
			},
			splitLine: {
				show: false,
			},
		},
	],
	series: [
		{
			name: '租金收入',
			type: 'bar',
			barWidth: 8,
			barGap: '100%',
			itemStyle: getLinearColor('#5CFFA4', '#046039'),
			tooltip: formatterTooltip('万元'),
			data: [],
		},
		{
			name: '违约金收入',
			type: 'bar',
			barWidth: 8,
			barGap: '100%',
			itemStyle: getLinearColor('#805F4B', '#FFAC54'),
			tooltip: formatterTooltip('万元'),
			data: [],
		},
		{
			name: '整备收入',
			type: 'bar',
			barWidth: 8,
			barGap: '100%',
			itemStyle: getLinearColor('#5CDAFF', '#106FA0'),
			tooltip: formatterTooltip('万元'),
			data: [],
		},
		{
			name: '车辆出租率',
			type: 'line',
			connectNulls: true,
			yAxisIndex: 1,
			showSymbol: true,
			symbolSize: 6,
			// label: {
			// 	show: true,
			// 	fontSize: 12,
			// 	color: '#ffffff',
			// 	formatter: '{c}%',
			// },
			tooltip: formatterTooltip('%'),
			itemStyle: {
				color: '#009DFF',
			},
			data: [],
		},
	],
}
export const zcqs = {
	color: ['#fff'],
	legend: {
		...legendStyle,
	},
	grid: {
		...gridStyle,
		right: 30,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'axis',
		axisPointer: {
			type: 'cross',
			label: {
				color: '#000129',
				backgroundColor: '#E1F5FF',
			},
			crossStyle: {
				color: '#E1F5FF',
			},
		},
	},
	xAxis: [
		{
			...xAxisStyle,
			type: 'category',
			data: [],
		},
	],
	yAxis: [
		{
			...yAxisStyle,
			name: '万元',
			alignTicks: true,
		},
	],
	series: [
		{
			name: '维保支出',
			type: 'bar',
			barWidth: 8,
			barGap: '100%',
			itemStyle: getLinearColor('#805F4B', '#FFAC54'),
			tooltip: formatterTooltip('万元'),
			data: [],
		},
		{
			name: '整备支出',
			type: 'bar',
			barWidth: 8,
			barGap: '100%',
			itemStyle: getLinearColor('#5CDAFF', '#106FA0'),
			tooltip: formatterTooltip('万元'),
			data: [],
		},
	],
}
export const htslfx = {
	legend: {
		...legendStyle,
	},
	grid: {
		...gridStyle,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'axis',
		axisPointer: {
			type: 'shadow',
		},
	},
	xAxis: {
		...xAxisStyle,
		data: [],
	},
	yAxis: {
		...yAxisStyle,
		name: '人',
	},
	series: [
		{
			name: '新增合同数',
			type: 'bar',
			barWidth: 16,
			itemStyle: getLinearColor('#5CFFA4', '#046039'),
			tooltip: formatterTooltip('人'),
			data: [],
		},
		{
			name: '结束合同数',
			type: 'bar',
			barWidth: 16,
			itemStyle: getLinearColor('#FFA951', '#FF5201'),
			tooltip: formatterTooltip('人'),
			data: [],
		},
	],
}
export const wldqhts = {
	legend: {
		...legendStyle,
	},
	grid: {
		...gridStyle,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'axis',
		axisPointer: {
			type: 'shadow',
		},
	},
	xAxis: {
		...xAxisStyle,
		data: [],
	},
	yAxis: {
		...yAxisStyle,
		name: '人',
	},
	series: [
		{
			name: '合同到期数',
			type: 'bar',
			barWidth: 16,
			itemStyle: getLinearColor('#5CFFA4', '#046039'),
			tooltip: formatterTooltip('人'),
			data: [],
		},
	],
}
export const cxczl = {
	...getLineOption('%'),
}
export const cxzqfb = {
	color: pieColors,
	legend: {
		show: false,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'item',
	},
	series: [
		{
			...pieStyle,
			name: '车系租期分布',
			type: 'pie',
			radius: ['70%', '85%'],
			tooltip: formatterTooltip('次'),
			data: [],
		},
	],
}
export const gssrgc = {
	color: pieColors,
	legend: {
		show: false,
	},
	tooltip: {
		...tooltipStyle,
		formatter: '{b0}<br />{c0}万元',
		trigger: 'item',
	},
	series: [
		{
			...pieStyle,
			name: '公司收入构成',
			type: 'pie',
			radius: ['70%', '85%'],
			data: [],
		},
	],
}
export const cxtjyz = {
	...getLineOption('元'),
	grid: {
		...gridStyle,
		right: 30,
		left: 60,
	},
}
export const cxljzj = {
	...getLineOption('万元'),
}
export const ptsrgc = {
	color: pieColors,
	legend: {
		show: false,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'item',
	},
	series: [
		{
			...pieStyle,
			name: '平台收入构成',
			type: 'pie',
			radius: ['70%', '85%'],
			tooltip: formatterTooltip('万元'),
			data: [],
		},
	],
}
export const cxxzyj = {
	...getLineOption('万元'),
}
export const cxljyj = {
	...getLineOption('万元'),
}
export const tczbf = {
	color: ['#FFAC54', '#5CDAFF'],
	legend: {
		...legendStyle,
		data: ['整备支出', '净收入'],
	},
	grid: {
		...gridStyle,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'axis',
		axisPointer: {
			type: 'shadow',
		},
	},
	xAxis: {
		...xAxisStyle,
		data: [],
	},
	yAxis: {
		...yAxisStyle,
		name: '万元',
	},
	series: [
		{
			...stackSeriesOfBarStyle,
			name: '整备支出',
			tooltip: formatterTooltip('万元'),
			itemStyle: getLinearColor('#5CDAFF', '#106FA0'),
			data: [],
		},
		{
			...stackSeriesOfBarStyle,
			name: '净收入',
			tooltip: formatterTooltip('万元'),
			itemStyle: getLinearColor('#5CFFA4', '#046039'),
			data: [],
		},
	],
}
export const wbtjje = {
	color: ['#FFF', '#FFAC54', '#5CDAFF'],
	legend: {
		...legendStyle,
	},
	grid: {
		...gridStyle,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'axis',
		axisPointer: {
			type: 'shadow',
		},
	},
	xAxis: {
		...xAxisStyle,
		// type: 'value',
		data: [],
	},
	yAxis: {
		...yAxisStyle,
		// type: 'category',
		name: '元',
	},
	series: [
		{
			...stackSeriesOfBarStyle,
			name: '维修',
			// stack: 'total',
			tooltip: formatterTooltip('元'),
			itemStyle: {
				color: '#FAA14B',
			},
			data: [],
		},
		{
			...stackSeriesOfBarStyle,
			name: '保养',
			// stack: 'total',
			tooltip: formatterTooltip('元'),
			itemStyle: {
				color: '#51EB96',
			},
			data: [],
		},
		{
			...stackSeriesOfBarStyle,
			name: '易损件',
			// stack: 'total',
			tooltip: formatterTooltip('元'),
			itemStyle: {
				color: '#5CDAFF',
			},
			data: [],
		},
	],
}
export const wbljje = {
	color: ['#FAA14B', '#51EB96', '#5CDAFF'],
	legend: {
		...legendStyle,
	},
	grid: {
		...gridStyle,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'axis',
		axisPointer: {
			type: 'shadow',
		},
	},
	xAxis: {
		...xAxisStyle,
		data: [],
	},
	yAxis: {
		...yAxisStyle,
		name: '万元',
	},
	series: [
		{
			...stackSeriesOfBarStyle,
			name: '维修',
			tooltip: formatterTooltip('万元'),
			data: [],
		},
		{
			...stackSeriesOfBarStyle,
			name: '保养',
			tooltip: formatterTooltip('万元'),
			data: [],
		},
		{
			...stackSeriesOfBarStyle,
			name: '易损件',
			tooltip: formatterTooltip('万元'),
			data: [],
		},
	],
}
export const wzfx = {
	color: ['#FFAC54'],
	legend: {
		...legendStyle,
	},
	grid: {
		...gridStyle,
		right: 30,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'axis',
		axisPointer: {
			type: 'cross',
			label: {
				color: '#000129',
				backgroundColor: '#E1F5FF',
			},
			crossStyle: {
				color: '#E1F5FF',
			},
		},
	},
	xAxis: [
		{
			...xAxisStyle,
			type: 'category',
			data: [],
		},
	],
	yAxis: [
		{
			...yAxisStyle,
			name: '元',
			alignTicks: true,
		},
		{
			...yAxisStyle,
			name: '次',
			nameTextStyle: {
				color: '#A1AEC1',
				padding: [0, 0, 0, 30],
			},
			splitLine: {
				show: false,
			},
		},
	],
	series: [
		{
			name: '违章次数',
			type: 'line',
			connectNulls: true,
			yAxisIndex: 1,
			showSymbol: true,
			symbolSize: 6,
			label: {
				show: true,
				fontSize: 12,
				color: '#ffffff',
				formatter: '{c}次',
			},
			tooltip: formatterTooltip('次'),
			data: [],
		},
		{
			name: '违章金额',
			type: 'bar',
			barWidth: 16,
			itemStyle: getLinearColor('#5CDAFF', '#106FA0'),
			tooltip: formatterTooltip('元'),
			data: [],
		},
	],
}
export const sgfx = {
	color: ['#FFAC54'],
	legend: {
		...legendStyle,
	},
	grid: {
		...gridStyle,
		right: 30,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'axis',
		axisPointer: {
			type: 'cross',
			label: {
				color: '#000129',
				backgroundColor: '#E1F5FF',
			},
			crossStyle: {
				color: '#E1F5FF',
			},
		},
	},
	xAxis: [
		{
			...xAxisStyle,
			type: 'category',
			data: [],
		},
	],
	yAxis: [
		{
			...yAxisStyle,
			name: '元',
			alignTicks: true,
		},
		{
			...yAxisStyle,
			name: '次',
			nameTextStyle: {
				color: '#A1AEC1',
				padding: [0, 0, 0, 30],
			},
			splitLine: {
				show: false,
			},
		},
	],
	series: [
		{
			name: '有责事故金额',
			type: 'bar',
			barWidth: 16,
			itemStyle: getLinearColor('#5CDAFF', '#106FA0'),
			tooltip: formatterTooltip('元'),
			data: [],
		},
		{
			name: '有责事故次数',
			type: 'line',
			connectNulls: true,
			yAxisIndex: 1,
			showSymbol: true,
			symbolSize: 6,
			label: {
				show: true,
				fontSize: 12,
				color: '#ffffff',
				formatter: '{c}次',
			},
			tooltip: formatterTooltip('次'),
			data: [],
		},
	],
}
export const yqysjdyfb = {
	color: pieColors,
	legend: {
		show: false,
	},
	tooltip: {
		...tooltipStyle,
		formatter: '{b0}<br />{c0}人',
		trigger: 'item',
	},
	series: [
		{
			...pieStyle,
			name: '已签约司机地域分布',
			type: 'pie',
			radius: ['80%'],
			data: [],
		},
	],
}
export const yqysjnlfb = {
	color: pieColors,
	legend: {
		show: false,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'item',
	},
	series: [
		{
			...pieStyle,
			name: '已签约司机年龄分布',
			type: 'pie',
			radius: ['80%'],
			tooltip: formatterTooltip('人'),
			data: [],
		},
	],
}
export const yqysjxbfb = {
	color: pieColors,
	legend: {
		show: false,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'item',
	},
	series: [
		{
			...pieStyle,
			name: '已签约司机性别分布',
			type: 'pie',
			radius: ['80%'],
			tooltip: formatterTooltip('人'),
			data: [],
		},
	],
}
export const wyjsr = {
	color: ['#fff'],
	legend: {
		...legendStyle,
	},
	grid: {
		...gridStyle,
		right: 30,
	},
	tooltip: {
		...tooltipStyle,
		trigger: 'axis',
		axisPointer: {
			type: 'cross',
			label: {
				color: '#000129',
				backgroundColor: '#E1F5FF',
			},
			crossStyle: {
				color: '#E1F5FF',
			},
		},
	},
	xAxis: [
		{
			...xAxisStyle,
			type: 'category',
			data: [],
		},
	],
	yAxis: [
		{
			...yAxisStyle,
			name: '万元',
			alignTicks: true,
		},
	],
	series: [
		{
			name: '违约金收入',
			type: 'bar',
			barWidth: 8,
			barGap: '100%',
			itemStyle: getLinearColor('#FFAC54', '#FF5200'),
			tooltip: formatterTooltip('万元'),
			data: [],
		},
	],
}
